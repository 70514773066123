body,
html,
h1,
h2,
h3,
h4 {
  margin: 0;
  font-family: 'Amaranth', sans-serif !important;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  overflow-y: auto;
  height: 100%;
}

.makeStyles-paper-11,
.makeStyles-paper-11 h2,
.MuiTypography-body1,
.MuiPaper-root,
.MuiPaper-root h2 {
  color: #dc9d3f !important;
}

.MuiPaper-root {
  color: #935f10 !important;
}

body {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-position: center center !important;
  background-attachment: fixed !important;
  background-size: cover !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Caesar Dressing', cursive !important;
  text-shadow: -2px 0 black, 0 2px black, 2px 0 black, 0 -2px black;
}

footer .a:hover {
  fill: '#DA4567';
}

.MuiToolbar-regular {
  color: #e0e3bd;
}

/* .MuiTypography-colorTextPrimary,
.MuiButton-root, */
.MuiButton-containedPrimary,
.MuiButton-containedPrimary:hover,
.MuiListItem-button:hover,
.MuiButtonBase-root:hover {
  color: #fff !important;
}

/* .MuiButton-containedPrimary {
	background-color: #EFCF83 !important;
} */

.MuiButton-outlined {
  background-color: #543c2c !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.wallet-button {
  color: #dc9d3f !important;
  font-family: 'Amaranth', sans-serif !important;
  font-size: 1.1rem !important;
  text-align: left !important;
  justify-content: flex-start !important;
  text-transform: none !important;
}
.wallet-button:hover {
  background-color: black !important;
  color: #F4AE00 !important;
}

/* NOVO */
/* .check,
.check2,
.check3,
.check4 {
		position: relative;
	background: linear-gradient(90deg, #dc9d3f, #F4AE00);
	line-height: 0;
  height: 16px;
	perspective: 400px;
}

.check input[type="checkbox"],
.check label,
.check label::before,
.check label::after,
.check,
.check2 input[type="checkbox"],
.check2 label,
.check2 label::before,
.check2 label::after,
.check2,
.check3 input[type="checkbox"],
.check3 label,
.check3 label::before,
.check3 label::after,
.check3,
.check4 input[type="checkbox"],
.check4 label,
.check4 label::before,
.check4 label::after,
.check4 {
	appearance: none;
	display: inline-block;
	border-radius: 1rem;
	border: 0;
	transition: .35s ease-in-out;
	box-sizing: border-box;
	cursor: pointer;
}

.check label,
.check2 label,
.check3 label,
.check4 label {
		width: 2.2rem;
	height: 1rem;
	background: #d7d7d7;
	overflow: hidden;
}

.check input[type="checkbox"],
.check2 input[type="checkbox"],
.check3 input[type="checkbox"],
.check4 input[type="checkbox"] {
		position: absolute;
	z-index: 1;
	width: .8rem;
	height: .8rem;
	top: -.1rem;
	left: -.1rem;
	background: linear-gradient(45deg, #dedede, #ffffff);
	box-shadow: 0 6px 7px rgba(0,0,0,0.3);
	outline: none;
}

.check input[type="checkbox"]:checked,
.check2 input[type="checkbox"]:checked,
.check3 input[type="checkbox"]:checked,
.check4 input[type="checkbox"]:checked {
		left: 1.0rem;
}

.check input[type="checkbox"]:checked + label,
.check2 input[type="checkbox"]:checked + label,
.check3 input[type="checkbox"]:checked + label,
.check4 input[type="checkbox"]:checked + label {
		background: transparent;
}

.check label::before,
.check label::after,
.check2 label::before,
.check2 label::after,
.check3 label::before,
.check3 label::after,
.check4 label::before,
.check4 label::after {
	content: "· ·";
	position: absolute;
	overflow: hidden;
	left: .15rem;
	top: .5rem;
	height: 1rem;
	letter-spacing: -.04rem;
	color: #9b9b9b;
	font-family: "Times New Roman", serif;
	z-index: 2;
	font-size: .6rem;
	border-radius: 0;
	transform-origin: 0 0 -.5rem;
	backface-visibility: hidden;
}

.check label::after,
.check2 label::after,
.check3 label::after,
.check4 label::after {
	content: "●";
	top: .65rem;
	left: .3rem;
	height: .1rem;
	width: .35rem;
	font-size: .2rem;
	transform-origin: 0 0 -.4rem;
}

.check input[type="checkbox"]:checked + label::before,
.check input[type="checkbox"]:checked + label::after,
.check2 input[type="checkbox"]:checked + label::before,
.check2 input[type="checkbox"]:checked + label::after,
.check3 input[type="checkbox"]:checked + label::before,
.check3 input[type="checkbox"]:checked + label::after,
.check4 input[type="checkbox"]:checked + label::before,
.check4 input[type="checkbox"]:checked + label::after {
	left: 1.55rem;
	top: .4rem;
	line-height: .1rem;
	transform: rotateY(360deg);
}

.check input[type="checkbox"]:checked + label::after,
.check2 input[type="checkbox"]:checked + label::after,
.check3 input[type="checkbox"]:checked + label::after,
.check4 input[type="checkbox"]:checked + label::after {
		height: .16rem;
	top: .55rem;
	left: 1.6rem;
	font-size: .6rem;
	line-height: 0;
} */

/* Checks */

.check,
.check2,
.check3,
.check4 {
	position: relative;
	background: linear-gradient(90deg, #dcc23f, #f4e000);
	line-height: 0;
	height: 40px;
	width: 100%;
}

.check input[type="checkbox"],
.check label,
.check label::before,
.check label::after,
.check,
.check2 input[type="checkbox"],
.check2 label,
.check2 label::before,
.check2 label::after,
.check2,
.check3 input[type="checkbox"],
.check3 label,
.check3 label::before,
.check3 label::after,
.check3,
.check4 input[type="checkbox"],
.check4 label,
.check4 label::before,
.check4 label::after,
.check4 {
	appearance: none;
	display: inline-block;
	border-radius: 5px;
	border: 0;
	transition: .35s ease-in-out;
	box-sizing: border-box;
	cursor: pointer;
}

.check label,
.check2 label,
.check3 label,
.check4 label {
	height: 40px;
	width: 100%;
	background: #d7d7d7;
	overflow: hidden;
}

.check input[type="checkbox"],
.check2 input[type="checkbox"],
.check3 input[type="checkbox"],
.check4 input[type="checkbox"] {
		position: absolute;
	z-index: 1;
	width: 37px;
	height: 34px;
	background: linear-gradient(45deg, #dedede, #ffffff);
	box-shadow: 0 6px 7px rgba(0,0,0,0.3);
	outline: none;
}

.check input[type="checkbox"]:checked,
.check2 input[type="checkbox"]:checked,
.check3 input[type="checkbox"]:checked,
.check4 input[type="checkbox"]:checked {
		left: calc(100% - 43px);
}

.check input[type="checkbox"]:checked + label,
.check2 input[type="checkbox"]:checked + label,
.check3 input[type="checkbox"]:checked + label,
.check4 input[type="checkbox"]:checked + label {
		background: transparent;
}

.check label::before,
.check label::after,
.check2 label::before,
.check2 label::after,
.check3 label::before,
.check3 label::after,
.check4 label::before,
.check4 label::after {
	content: "· ·";
	position: absolute;
	overflow: hidden;
	left: .15rem;
	top: .5rem;
	height: 1rem;
	letter-spacing: -.04rem;
	color: #9b9b9b;
	font-family: "Times New Roman", serif;
	z-index: 2;
	font-size: .6rem;
	border-radius: 0;
	transform-origin: 0 0 -.5rem;
	backface-visibility: hidden;
	visibility: hidden;
}

.check label::after,
.check2 label::after,
.check3 label::after,
.check4 label::after {
	content: "●";
	top: .65rem;
	left: .3rem;
	height: .1rem;
	width: .35rem;
	font-size: .2rem;
	transform-origin: 0 0 -.4rem;
}

.check input[type="checkbox"]:checked + label::before,
.check input[type="checkbox"]:checked + label::after,
.check2 input[type="checkbox"]:checked + label::before,
.check2 input[type="checkbox"]:checked + label::after,
.check3 input[type="checkbox"]:checked + label::before,
.check3 input[type="checkbox"]:checked + label::after,
.check4 input[type="checkbox"]:checked + label::before,
.check4 input[type="checkbox"]:checked + label::after {
	left: calc(100% - 43px);
	top: .4rem;
	line-height: .1rem;
	transform: rotateY(360deg);
}

.check input[type="checkbox"]:checked + label::after,
.check2 input[type="checkbox"]:checked + label::after,
.check3 input[type="checkbox"]:checked + label::after,
.check4 input[type="checkbox"]:checked + label::after {
	height: .16rem;
	top: .55rem;
	left: 1.6rem;
	font-size: .6rem;
	line-height: 0;
	visibility: hidden;
}

.MuiMenu-paper {
	background-color: #121212 !important;
}

.MuiMenu-list {
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
}