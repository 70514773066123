
  .theme-1 {
    --color-primary: #0060df;
    --color-secondary: #fbfbfe;
    --color-accent: #fd6f53;
    --font-color: #000000;
  }
  .theme-2 {
    --color-primary: #17ed90;
    --color-secondary: #243133;
    --color-accent: #12cdea;
    --font-color: #ffffff;
  }
  .theme-3 {
    --color-primary: #17ed90;
    --color-secondary: #243133;
    --color-accent: #12cdea;
    --font-color: #ffffff;
  }
  .theme-4 {
    --color-primary: #17ed90;
    --color-secondary: #243133;
    --color-accent: #12cdea;
    --font-color: #ffffff;
  }